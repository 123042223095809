<template>
  <div class="how-works-v2">
    <div class="container">
      <h2 v-if="title" v-html="title" class="how-works-v2__title"/>
      <div v-if="message" v-html="message" class="how-works-v2__message"/>
      <div class="how-works-v2__items">
        <div
          v-for="(_item, index) in items"
          :key="`how-works-v2-item-${ index }`"
          class="how-works-v2-item"
        >
          <div class="how-works-v2-item__index">{{ String(index + 1).padStart(2, '0') }}</div>
          <div>
            <div v-html="_item.title" class="how-works-v2-item__title"/>
            <div v-html="_item.message" class="how-works-v2-item__message"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from "#sitis/internal/models/common";
import type { ComputedRef } from 'vue';
const props = defineProps<IBlockDefaultProps>();

const title: ComputedRef<string> = computed(() => {
  return props?.values?.title || ""
});
const message: ComputedRef<string> = computed(() => {
  return props?.values?.text || ""
});
const items: ComputedRef<any[]> = computed(() => {
  return props?.values?.items || [];
});
</script>

<style scoped lang="scss">
.how-works-v2 {
  margin: 56px 0;
}
.how-works-v2__title {
  margin-bottom: 8px;

  font-size: 48px;
  font-weight: 600;
  color: hsl(var(--main-section-tx)/1);
}
.how-works-v2__message {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 500;
  color: hsla(var(--main-section-tx)/0.6);
}
.how-works-v2__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-left: -40px;

  & > * {
    width: calc(100% / 3 - 40px);
    margin-left: 40px;
  }
}

.how-works-v2-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.how-works-v2-item__index {
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background: var(--primary-bg-gradient);
  box-shadow: 0px 2px 5px 0px hsla(var(--primary-bg)/0.25), 0px 9px 9px 0px hsla(var(--primary-bg)/0.21), 0px 20px 12px 0px hsla(var(--primary-bg)/0.13), 0px 35px 14px 0px hsla(var(--primary-bg)/0.04), 0px 55px 15px 0px hsla(var(--primary-bg)/0), inset 0px 4px 4px 0px hsla(var(--white)/0.25);

  font-size: 36px;
  line-height: 1;
  font-weight: 500;
  color: hsla(var(--white)/1);
  text-shadow: 0px 2px 8px hsla(var(--black)/0.25);
}
.how-works-v2-item__title {
  margin-top: 18px;
  margin-bottom: 12px;

  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  color: hsla(var(--page-tx)/1);
  letter-spacing: -0.02em;
}
.how-works-v2-item__message {
  font-size: 16px;
  line-height: 1.3;
  color: hsla(var(--link-tx)/1);
  letter-spacing: -0.02em;
}

@media (max-width: 1023px) {
  .how-works-v2__title {
    font-size: 32px;
  }
  .how-works-v2__message {
    font-size: 18px;
  }
  .how-works-v2__items {
    flex-direction: column;
    margin-left: 0;
    margin-top: 20px;
    row-gap: 24px;

    & > * {
      width: 100%;
      margin-left: 0;
    }
  }

  .how-works-v2-item {
    flex-direction: row;
    gap: 16px;
  }
  .how-works-v2-item__title {
    margin-top: 0;
    margin-bottom: 8px;
  }
}
</style>
